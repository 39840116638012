import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import VueRouter from 'vue-router';
import { getItem } from '@/utils/storage';
Vue.use(VueRouter);
var originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

var routes = [{
  path: '/',
  component: function component() {
    return import('@/views/Layout/');
  },
  children: [{
    path: '',
    name: 'home',
    component: function component() {
      return import('@/views/Home/index');
    }
  }, {
    path: '/my',
    name: 'my',
    component: function component() {
      return import('@/views/My/index');
    }
  }, {
    path: '/wallet',
    component: function component() {
      return import('@/views/Wallet/index');
    },
    children: [{
      path: '/wallet/loansComponent',
      name: 'loansComponent',
      component: function component() {
        return import('@/views/Wallet/component/loansComponent');
      }
    }, {
      path: '/wallet/withdrawdeposit',
      name: 'withdrawdeposit',
      component: function component() {
        return import('@/views/Wallet/component/withdrawdeposit');
      }
    }]
  }, {
    path: '/service',
    name: 'service',
    component: function component() {
      return import('@/views/Service/index');
    }
  }]
}, // home下的Page
{
  path: '/home/loanapplication',
  name: 'loanapplication',
  component: function component() {
    return import('@/views/Home/loanapplication');
  }
}, // login下的page
{
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/views/Login/login');
  }
}, {
  path: '/register',
  name: 'register',
  component: function component() {
    return import('@/views/Login/register');
  }
}, {
  path: '/forget',
  name: 'forget',
  component: function component() {
    return import('@/views/Login/forget');
  }
}, // my下的page
{
  path: '/my/mydata',
  name: 'mydata',
  component: function component() {
    return import('@/views/My/mydata');
  }
}, // /my/mydata下的页面
{
  path: '/my/mydata/identitydata',
  name: 'identitydata',
  component: function component() {
    return import('@/views/My/identitydata');
  }
}, {
  path: '/my/mydata/openlevel',
  name: 'openlevel',
  component: function component() {
    return import('@/views/My/openlevel');
  }
}, {
  path: '/my/mydata/datamessage',
  name: 'datamessage',
  component: function component() {
    return import('@/views/My/datamessage');
  }
}, {
  path: '/my/mydata/receiptBankCard',
  name: 'receiptBankCard',
  component: function component() {
    return import('@/views/My/receiptBankCard');
  }
}, {
  path: '/my/mydata/furtherinformation',
  name: 'furtherinformation',
  component: function component() {
    return import('@/views/My/furtherinformation');
  }
}, {
  path: '/my/myborrow',
  name: 'myborrow',
  component: function component() {
    return import('@/views/My/myborrow');
  }
}, {
  path: '/my/myborrow/borrowdetail',
  name: 'borrowdetail',
  component: function component() {
    return import('@/views/My/borrowdetail');
  }
}, {
  path: '/my/myrefund',
  name: 'myrefund',
  component: function component() {
    return import('@/views/My/myrefund');
  }
}, {
  path: '/my/changepassword',
  name: 'changepassword',
  component: function component() {
    return import('@/views/My/changepassword');
  }
}, {
  path: '/my/ht',
  name: 'ht',
  component: function component() {
    return import('@/views/My/ht');
  }
}, // 钱包下的页面
{
  path: '/wallet/recharge',
  name: 'recharge',
  component: function component() {
    return import('@/views/Wallet/recharge');
  }
}, // 我的提现
{
  path: '/my/wywithdrwdeposit',
  name: 'wywithdrwdeposit',
  component: function component() {
    return import('@/views/My/wywithdrwdeposit');
  }
}, {
  path: '/my/wywithdrwdepositdetail',
  name: 'wywithdrwdepositdetail',
  component: function component() {
    return import('@/views/My/wywithdrwdepositdetail');
  }
}, {
  path: '/app',
  name: 'app',
  component: function component() {
    return import('@/views/APP/index');
  }
}, {
  path: '/my/apassword',
  name: 'apassword',
  component: function component() {
    return import('@/views/My/apassword');
  }
}, // 电子签名
{
  path: '/signature',
  name: 'signature',
  component: function component() {
    return import('@/views/My/signature');
  }
}, // 合同
{
  path: '/hetong',
  name: 'hetong',
  component: function component() {
    return import('@/views/My/hetong');
  }
}];
var router = new VueRouter({
  routes: routes
});
router.beforeEach(function (to, from, next) {
  // console.log(to.path)
  // console.log(getItem('token'))
  if (getItem('b_token')) {
    next();
  } else {
    if (to.path === '/register' || to.path === '/' || to.path === '/login' || to.path === '/forget' || to.path === '/home') {
      next();
    } else {
      next('/login');
    }
  }
});
export default router;