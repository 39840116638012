module.exports = {
  HOME: 'Beranda',
  WALLET: 'Saldo',
  SERVICE: 'Help',
  MY: 'Akun',
  APPLYMONEY: 'Jumlah Aplikasi(Rupiah)',
  AWAITJIE: 'Saldo Yang Ditarik(Rupiah)',
  UNIT: 'Rupiah',
  BORRORWMONEYTIME: 'jangka waktu pinjaman',
  EVERYISSUE: 'pembayaran bulanan',
  DAYRATE: 'suku bunga bulanan',
  MOTHLYINTEREST: 'bunga bulanan',
  CONSENT: 'setuju',
  AGREEMENTENT: '《perjanjian pinjaman》',
  ATONECBORROWMONEY: 'Pinjam sekarang',
  CONSENTPROTOCOL: 'Harap setujui dan centang perjanjian',
  LOANAPPLICATION: 'Ajukan pinjaman',
  CONSENTBORROWMESSAGE: 'Konfirmasi informasi pinjaman',
  BORRORWMONEY: 'Jumlah pinjaman',
  ACCOUNTBANK: 'Ke bank',
  PAYEEBOX: 'Akun koleksi',
  SUBMITBORROWTEXT: 'mengajukan debet',
  PLEACHECKPROTOCOL: 'Harap centang perjanjian ini',
  MONTHS: 'bulan',
  USERNAME: 'nama belakang',
  PASSWORD: 'kata sandi',
  SUBMIT: 'kirim',
  CHECKUSERNMAE: 'Nama pengguna tidak boleh kosong',
  CHECKPASSWORD: 'kata sandi tidak boleh kosong',
  REGISTER: 'daftar',
  FORGET: 'lupa kata sandi',
  NOTCHINA: 'Tidak bisa menggunakan bahasa Mandarin',
  LIJIRECHARGE: 'top up sekarang',
  CANCEL: 'Membatalkan',
  RECHARGEMONEY: 'Jumlah isi ulang',
  WITHDRAWDEPOSIT: 'Jumlah penarikan',
  WITHDRAWDEPOSITPASSWORD: 'Tarik kata sandi',
  INPUTWITHDRAWDEPOSITPASSWORD: 'Silakan masukkan kata sandi penarikan',
  WITHDRAWDEPOSITMONEYNULL: 'Jumlah penarikan tidak boleh kosong',
  WITHDRAWDEPOSITNULL: 'Kata sandi penarikan tidak boleh kosong',
  MYDATA: 'Profil saya',
  MYBORROW: 'pinjaman saya',
  MYREFUND: 'pembayaran saya',
  OPENVIP: 'Buka VIP',
  CHANGEPASSWORD: 'ganti kata sandi',
  LOGOUT: 'keluar',
  MSUTDATAFULL: 'Berikut informasi yang harus diisi dan dilengkapi untuk mengajukan pinjaman',
  IDENTITYDATA: 'Informasi Identitas',
  UNDERSTANDBASICINFORMATION: 'Beri tahu kami situasi dasar Anda',
  DATAMESSAGE: 'Informasi',
  RECEIPTBANKCARD: 'Menerima kartu bank',
  PHONENUMVERIFY: 'verifikasi nomor ponsel',
  VERIFYSELFPHONENUM: 'Verifikasi nomor ponsel Anda sendiri',
  INCREASETHEPASSRATE: 'Berikut ini adalah informasi tambahan opsional untuk meningkatkan jumlah dan kemungkinan persetujuan',
  FURTHERINFORMATION: 'bahan tambahan',
  REVIEWTHEPASSRATE: 'Tingkatkan peluang persetujuan Anda',
  FILLED: 'terisi',
  FILLINTRUEINFORMATION: 'Isi informasi yang sebenarnya dan valid, dan ulasan akan berlalu.~',
  NAME: 'nama',
  IDCARDNUMBER: 'nomor identifikasi',
  UPLOADIDPROTO: 'Unggah foto identitas',
  IDPOSITIVEPROTO: 'foto depan KTP',
  IDONTHEBRECKPROTO: 'Foto bagian belakang KTP',
  HANDTAKEIDPROTO: 'foto identitas di tangan',
  ENTITYNAME: 'nama perusahaan',
  POSITION: 'Posisi',
  ENTITYPHONE: 'telepon Kantor',
  WORKAGE: '工龄',
  CURRENTRESIDENCEDETAILS: 'Alamat rinci tempat tinggal saat ini',
  CONTACTOFIMMEDIATEFAMILY: 'Kontak Keluarga Segera',
  CONTACTNAME: 'Nama',
  CONTACTPHONE: 'Nomor telepon',
  CONTACTRELATION: 'hubungan',
  OTHER: 'kontak lainnya',
  TAKECARDNAME: 'Nama pemegang kartu',
  TAKEID: 'ID Pemegang Kartu',
  BANKNAME: 'Bank setoran',
  BANKCRAD: 'nomor rekening',
  BANKCRADSELF: 'Pengingat: Kartu bank yang diisi harus berupa kartu debit atas nama saya（Kartu debit）。',
  INPUTBANKCARDNUMBER: 'Masukkan nomor kartu bank',
  SAVEDATA: 'menambahkan informasi',
  BORRORWDETAIL: 'Detail peminjaman',
  BORRORWPLAN: 'Progres pinjaman',
  SUBMITSUCCEED: 'SEDANG DI PROSES',
  ARRIVEMONEYSUCCEED: 'Berhasil diterima',
  ORDERFORMNUM: 'jumlah order',
  REFUNDSTATE: 'Instruksi pembayaran',
  NEEDNOTBORRORW: 'tidak ada pembayaran yang diperlukan！',
  CURRENTMONTHNEEDNOTBORRORW: 'Anda tidak memiliki tagihan untuk membayar bulan ini',
  NOTE: 'Verifikasi SMS',
  NOTENUM: 'Kode verifikasi SMS',
  NWESPASSWORD: 'kata sandi baru',
  INPUTPHONE: 'Masukkan nomor telepon',
  INPUTNOTE: 'Silakan masukkan kode verifikasi SMS',
  INPUTNEWSPASSWORD: 'Harap tetapkan kata sandi 6-16 digit',
  CONFIRM: 'Tentu',
  PLEAINPUTNAME: 'Silakan ketik nama Anda',
  PLEAINPUTID: 'Silakan masukkan nomor ID',
  PLEAENTITYNAME: 'Harap masukkan nama unit',
  PLEAPOSITION: 'Silakan masukkan judul pekerjaan',
  PLEAENTITYPHONE: 'Masukkan nomor telepon unit',
  PLAEAWORKAGE: 'Masukkan usia kerja',
  PLEACURRENTRESIDENCEDETAILS: 'Silakan masukkan alamat rinci tempat tinggal Anda saat ini',
  PLEACONTACTRELATION: 'Misalnya：orang tua dan anak-anak',
  BORRORSERIALBUNBER: 'nomor pinjaman',
  TOTAILMONEY: 'jumlah bulat',
  RECHARGEMONEYNONTNULL: 'Jumlah isi ulang tidak boleh kosong',
  RECHARGE: 'Rincian Isi Ulang',
  UPLOADVOUCHER: 'unggah sertifikat',
  UPLOADONEPROTO: 'mengunggah gambar',
  USERREGISTER: 'pendaftaran pengguna',
  FORGETPASSWORD: 'mengambil kata sandi',
  NOTNULL: 'Tidak boleh kosong',
  LENGTH10: 'Panjangnya tidak boleh kurang dari 10',
  REGISTEROK: 'registrasi berhasil',
  LOGINOK: 'Berhasil login',
  AWAIT: 'Menunggu',
  THEACCOUNTBALANCE: 'Saldo rekening',
  UNFILLED: 'tidak terisi',
  UNITADDRESS: 'alamat satuan',
  INPUTUNITADDRESS: 'Masukkan alamat unit',
  OKBORRORW: 'peminjaman yang berhasil',
  PLEAMESSAGECOMPLETE: 'Mohon lengkapi informasinya',
  WORKCERTIFICATE: 'Bukti pekerjaan',
  INCOMEPROOF: 'bukti pendapatan',
  NOBRORROW: 'tidak ada pinjaman',
  MYWITHDRAWDEPOSIT: 'penarikan saya',
  NOMYWITHDRAWDEPOSIT: 'tidak ada informasi penarikan',
  MYWITHDRAWDEPOSITIME: 'Waktu penarikan',
  MYWITHDRAWDEPOSITMONEY: 'Jumlah penarikan',
  MYWITHDRAWDEPOSITDETAILS: 'Detail penarikan',
  VIP: 'anggota',
  LINK: 'Tautan',
  STUTA: 'negara',
  MESSAGE: 'informasi',
  MYWITHDRAWDEPOSILONG: 'Kemajuan penarikan',
  LOADAPP: 'Unduh APLIKASI',
  LIJITIXIAN: 'Tarik sekarang',
  TIXIANID: 'Nomor penarikan',
  WARMPROMPT: 'Tip yang baik',
  CONTACTME: 'Hubungi saya',
  QUESTION: 'FAQ',
  TOFAILURE: 'Akun gagal',
  WENXINGTISHI: 'Tampilan pengingat hangat，sedang ditinjau，Buka tinjauan dipercepat anggota',
  OPENVVIP: 'bergabung menjadi anggota',
  SEND_SMS: 'Kirim kode verifikasi',
  CUSTOMERSERVICECENTER: 'Pelayanan pelanggan',
  BORROWINGTIME: 'Waktu peminjaman',
  IMGNOT: 'Gambar tidak boleh kosong',
  PLEALOGIN: 'silakan masuk',
  Pleasereviewtheprotocolcarefully: 'Harap tinjau perjanjian dengan hati-hati',
  Lookatthecontract: 'lihat kontrak',
  FILLINALL: 'Silahkan isi semua',
  WDNUMBER: 'kode tunai',
  PWDWDNUMBER: 'Silakan masukkan kode penarikan',
  EDITTHEAMOUNT: 'edit jumlah',
  THENEWPASSWORD: 'Silakan masukkan kata sandi baru',
  MODIFYTHESUCCESS: 'Berhasil mengedit',
  MODIFYTHEFAILURE: 'gagal mengedit',
  CONTACTCUSTOMERSERVICE: 'Hubungi Layanan Pelanggan',
  TIX: 'menarik',
  CHECKPASSWORDRe: 'konfirmasi sandi',
  TWICE: 'Kata sandi yang dimasukkan dua kali tidak konsisten',
  HT: 'kontrak',
  LOGIN: 'Login',
  签名: 'Tanda Tangan Anda'
}
