import Vue from 'vue';
import Vuex from 'vuex';
import { getItem, setItem } from '@/utils/storage'; // import setItem from '@/utils/storage'

Vue.use(Vuex);
var TOKEN = 'b_token';
export default new Vuex.Store({
  state: {
    token: getItem(TOKEN)
  },
  mutations: {
    setToken: function setToken(state, data) {
      state.token = data;
      setItem(TOKEN, data);
    }
  },
  actions: {},
  modules: {}
});